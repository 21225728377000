import React from 'react';
import { getUserAvatarLetters } from "../../utils/function-utils";
import { AVATAR_BASE, STATIC_BASE } from "../../config";
import { ImageS3 } from '../ChatComponent/helpers/ImageS3';

const UserAvatar = ({ user = {}, className = '', showGroupMembersToggle, chatRoomType }) => {
  const onAvatarClick = () => {
    if (chatRoomType === 'group') {
      showGroupMembersToggle()
    }
  }

  let base = STATIC_BASE;
  if (user.avatar && !user.avatar.isGenerated) {
    base = AVATAR_BASE;
  }

  const s3Key = user.avatar?.s3Key || user.avatarS3Key;

  return <div className={`avatarContainer ${className}`}>
    <div className='avatarHolder' onClick={onAvatarClick}>
      {s3Key ? <ImageS3 imgKey={s3Key} imgName='GAvatar' /> : (user.avatar ? <img src={`${base}${user.avatar.path || user.avatar}`} alt='' /> : getUserAvatarLetters(user))}
    </div>
  </div>
};

export default UserAvatar;
