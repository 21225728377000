import React, { useEffect, useState } from "react";
import { Container, Button, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, Input, Label } from "reactstrap";
import "./EulaTextComponent.scss";
import RichTextEditor from "../AboutText/RichTextEditor/RichTextEditor";
import { ContentsApiService, ToastrService } from "../../../../services";
import { useTranslation } from "react-i18next";
import i18n from "../../../../i18n";
import { LanguageSelector } from "../../../UIComponents/LanguageSelector";
import { KeyValueListSelector } from "../../../UIComponents/KeyValueListSelector";
import { Loader } from "../../../UIComponents";
import moment from "moment";

const EulaTextComponent = ({ selectedTheme }) => {
  const [state, setState] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [lang, setLang] = useState(i18n.language);
  const [t] = useTranslation();
  const [showResetConfirmation, setShowResetConfirmation] = useState(false);
  const [showPublishConfirmation, setShowPublishConfirmation] = useState(false);
  const [editorKey, setEditorKey] = useState(0);
  const [historyList, setHistoryList] = useState([])
  const [selectedVersion, setSelectedVersion] = useState('')
  const [confirmPublishCheck, setConfirmPublishCheck] = useState(false);

  useEffect(() => {
    getPublishedData()
    getHistoryList();
  }, []);

  const getDraftData = () => {
    setIsLoading(true);
    new ContentsApiService().getDraftEulaTexts()
      .then((res) => {
        setState({
          eulaText: res.data.eulaText || {},
          cachedData: res.data
        });
        setSelectedVersion('draft')
        setEditorKey(prevKey => prevKey + 1);
      })
      .catch((err) => {
        const errorMessage = err?.message || 'An error occurred while fetching the content';
        ToastrService.error(errorMessage);
      })
      .finally(() => {
        setIsLoading(false)
      });
  };

  const getPublishedData = () => {
    setIsLoading(true);
    new ContentsApiService().getPublishedEulaTexts()
      .then((res) => {
        setState({
          eulaText: res.data.eulaText || {},
          cachedData: res.data
        });
        setSelectedVersion('published')
        setEditorKey(prevKey => prevKey + 1);
      })
      .catch((err) => {
        const errorMessage = err?.message || 'An error occurred while fetching the content';
        ToastrService.error(errorMessage);
      })
      .finally(() => {
        setIsLoading(false)
      });
  };

  const getHistoryList = () => {
    new ContentsApiService().getEulaHistoryList()
      .then((res) => {
        const list = res.data.list.map((item) => ({
          label: moment(item.label).format('YYYY-MM-DD HH:mm'),
          value: item.value,
        }))

        setHistoryList([
          {
            label: t('Select a version'),
            value: '',
          },
          {
            label: t('Draft version'),
            value: 'draft',
          },
          {
            label: t('Published version'),
            value: 'published',
          },
          ...list
        ])
      })
  }

  const saveData = () => {
    if (state.eulaText) {
      setIsLoading(true)

      new ContentsApiService()
        .updateDraftEulaTexts(state)
        .then((res) => {
              
          setSelectedVersion('draft')
          setState({
            ...state,
            update: false,
            cachedData: { ...state.cachedData, eulaText: state.eulaText }
          })

          ToastrService.success(t("admin.text_edit"))
        })
        .catch((err) => {
          const errorMessage = err?.message || 'An error occurred while saving the content';
          ToastrService.error(errorMessage);
        })
        .finally(() => {
          setIsLoading(false)
        });
    }
  };

  const resetData = () => {
    if (state.cachedData) {
      setState({
        ...state,
        eulaText: state.cachedData.eulaText || {},
        update: false,
        cachedData: state.cachedData
      });
      setEditorKey(prevKey => prevKey + 1);
    }
  }

  const editorProps = (key, lang) => ({
    value: state?.[key]?.[lang],
    onChange: (value) => setState({ ...state, [key]: { ...state[key], [lang]: value }, update: true }),
    darkMode: selectedTheme === 'dark'
  })

  const publishData = () => {
    setIsLoading(true)
    const data = {
      ...state,
      sendEmail: confirmPublishCheck
    }
    
    new ContentsApiService().publishEulaTexts(data)
    .then((res) => {
      setSelectedVersion('')
      setState({
        ...state,
        update: false,
        cachedData: state.eulaText
      })
      ToastrService.success(t("admin.text_publish"))
    })
    .catch((err) => {
      const errorMessage = err?.message || 'An error occurred while publishing the content';
      ToastrService.error(errorMessage);
    })
    .finally(() => {
      setIsLoading(false)
      getHistoryList()
    })
  }

  const toggleResetConfirmation = () => {
    setShowResetConfirmation(!showResetConfirmation);
  };

  const togglePublishConfirmation = () => {
    setShowPublishConfirmation(!showPublishConfirmation);
  };

  const confirmReset = () => {
    resetData();
    toggleResetConfirmation();
  };

  const confirmPublish = () => {
    publishData();
    togglePublishConfirmation();
    setConfirmPublishCheck(false);
  };

  const selectPreviousVersion = (value) => {
    if (value === '') {
      return
    }

    if (value === 'draft') {
      setSelectedVersion(value)
      getDraftData()
      return
    }

    if (value === 'published') {
      setSelectedVersion(value)
      getPublishedData()
      return
    }

    setIsLoading(true)

    new ContentsApiService().getEulaHistoryItem(value)
      .then((res) => {
        setSelectedVersion(value)
        setState({
          ...state,
          eulaText: res.data.eulaText || {}
        })
        setEditorKey(prevKey => prevKey + 1);
      })
      .catch((err) => {
        const errorMessage = err?.message || 'An error occurred while fetching the content';
        ToastrService.error(errorMessage);
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const getSubTitle = () => {
    if (selectedVersion && selectedVersion !== 'draft') {
      const version = historyList.find((item) => item.value === selectedVersion)
      if (version) {
        return `(${version.label})`
      }
    }

    if (state.update) {
      return `(${t('Unsaved draft')})`
    }

    return ''
  }

  return (
    <Container fluid className="EulaTextComponent">
      <div className="suffixesPage">
        <div className="bodyContent">
          {isLoading && (
            <div className="loader-overlay">
              <Loader />
            </div>
          )}
          <LanguageSelector onChange={setLang} value={lang} />
          <br />
          <br />
          {historyList.length > 0 && (
            <>
              <KeyValueListSelector name='eula-version' list={historyList} onChange={selectPreviousVersion} value={selectedVersion} label={t('admin.restore_version')} />
              <br />
              <br />
            </>
          )}
          <div className="text-editor">
            <div className="bodyHeader">
              <h2>{t("admin.eula")} {getSubTitle()}</h2>
            </div>
            <div className="content">
              <div className="preview">
                {lang === 'fr' ? <RichTextEditor key={editorKey} {...editorProps('eulaText', 'fr')} /> : <RichTextEditor key={editorKey + 1} {...editorProps('eulaText', 'en')} />}
              </div>
              <br />
              <div className="btn-content">
                <Button
                  className="submitButton draftButton"
                  color="primary"
                  disabled={!state.update}
                  onClick={toggleResetConfirmation}
                >
                  {t('Reset')}
                </Button>

                <Button
                  className="submitButton draftButton"
                  onClick={saveData}
                >
                  {t('Save Draft')}
                </Button>

                <Button
                  className="submitButton cancelButton"
                  disabled={selectedVersion == 'published' && !state.update}
                  onClick={togglePublishConfirmation}
                >
                  {t('Publish')}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Reset confirm modal */}
      <Modal isOpen={showResetConfirmation} toggle={toggleResetConfirmation} size="sm">
        <ModalHeader toggle={toggleResetConfirmation}>{t('CONFIRM')}</ModalHeader>
        <ModalBody>
          {t('admin.confirm_reset')}
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggleResetConfirmation}>{t('CANCEL')}</Button>
          <Button color="primary" onClick={confirmReset}>{t('CONFIRM')}</Button>
        </ModalFooter>
      </Modal>

      {/* Publish confirm modal */}
      <Modal isOpen={showPublishConfirmation} toggle={togglePublishConfirmation} size="sm">
        <ModalHeader toggle={togglePublishConfirmation}>{t('CONFIRM')}</ModalHeader>
        <ModalBody>
          {t('admin.confirm_publish')}
          <FormGroup check className="mt-3">
            <Label check>
              <Input
                type="checkbox"
                checked={confirmPublishCheck}
                onChange={(e) => setConfirmPublishCheck(e.target.checked)}
              />{' '}
              {t('admin.confirm_publish_check')}
            </Label>
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={togglePublishConfirmation}>{t('CANCEL')}</Button>
          <Button color="primary" onClick={confirmPublish}>{t('PUBLISH')}</Button>
        </ModalFooter>
      </Modal>
    </Container>
  );
};

export default EulaTextComponent;
