import BaseApiService from "./base";

export default class ContentsApiService extends BaseApiService {

  getDraftAboutTexts() {
    return this.request({
      method: "GET",
      url: "/content/about-texts",
      withCredentials: true,
    });
  }

  updateDraftAboutTexts(data) {
    return this.request({
      method: "POST",
      url: "/content/about-texts/draft",
      withCredentials: true,
      data,
    });
  }

  publishAboutTexts(data) {
    return this.request({
      method: "POST",
      url: "/content/about-texts/publish",
      withCredentials: true,
      data,
    });
  }

  getDraftEulaTexts() {
    return this.request({
      method: "GET",
      url: "/content/eula-texts",
      withCredentials: true,
    });
  }

  getEulaHistoryList() {
    return this.request({
      method: "GET",
      url: "/content/eula-texts/history",
      withCredentials: true,
    });
  }

  updateDraftEulaTexts(data) {
    return this.request({
      method: "POST",
      url: "/content/eula-texts/draft",
      withCredentials: true,
      data,
    });
  }

  publishEulaTexts(data) {
    return this.request({
      method: "POST",
      url: "/content/eula-texts/publish",
      withCredentials: true,
      data,
    });
  }

  getEulaHistoryItem(key) {
    return this.request({
      method: "GET",
      url: `/content/eula-texts/history/item`,
      withCredentials: true,
      params: {
        key
      }
    });
  }

  getPublishedEulaTexts() {
    return this.request({
      method: "GET",
      url: "/content/eula-texts/published",
      withCredentials: true,
    });
  }

}
