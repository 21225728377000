import React, { PureComponent } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import _ from "lodash";
import { chatStatusClassName } from "../../../helpers/chat";
import { CheckboxComponent, SelectField, ShowMore, UserAvatar } from "../../UIComponents";
import ImageIcon from '../../../assets/icons/image-icon.svg'
import ImageIconDark from '../../../assets/icons/image-icon-dark.svg'

import "./ImportanceModal.scss";
import { bindActionCreators } from "redux";
import { connect } from 'react-redux';
import { getCallingCodesList } from "../../../reducers/setting";
import { withTranslation } from "react-i18next";
import { S3AudioPlayer } from "../VoiceMessage/AudioPlayer";

class ImportanceModal extends PureComponent {
  state = {
    participants: [],
    dropdown: false,
    selected: [],
    selectSearch: "",
    importance: this.props.importance || "IMPORTANT",
    inputPhone: false,
    phoneCode: '(+1) - United States',
    phone: ''
  };

  componentDidMount() {
    if (this.props.participants && this.props.participants?.length > 0) {
      const filter = this.props.participants?.filter(
        (x) => x.id !== this.props.user.id
      );

      this.setState({ participants: filter, selected: filter });
    }

    if (!this.props.callingCodes?.length) {
      this.props.getCallingCodesList()
    }
  }

  componentDidUpdate(prevProps) {
    if (!_.isEqual(prevProps.participants, this.props.participants)) {
      const filter = this.props.participants?.filter(
        (x) => x.id !== this.props.user.id
      );
      this.setState({ participants: filter, selected: filter });
    }
  }

  handleParticipantSearch = (value) => {
    const filter = this.props.participants
      .filter((participant) =>
        participant?.fullName?.toLowerCase().includes(value?.toLowerCase())
      )
      .filter((x) => x.id !== this.props.user.id);
    this.setState({ participants: filter, selected: filter });
  };

  handleSubmit = () => {
    const { submitMessage, user } = this.props;
    const { participants, selected, importance, inputPhone } = this.state;

    // if (inputPhone && !phone) {
    //   ToastrService.error('Phone number is required')
    //   return;
    // }
    const users =
      selected.length > 0
        ? selected.map((x) => x.id)
        : participants.map((x) => x.id);
    submitMessage({
      level: importance,
      notify: users,
      phone: inputPhone ? user.phone : undefined,
      phoneCountryCode: inputPhone ? user.phoneCountryCode : undefined,
      phoneCountryName: inputPhone ? user.phoneCountryName : undefined,
      // ...(inputPhone ? extractCountryCodeAndName(phoneCode) : {})
    });
  };

  render() {
    const { isOpen, toggle, contacts, isGroup, files, theme, audioPreview, copiedVoice, textMessage, callingCodes, t } =
      this.props;
    const { participants, selected, selectSearch, dropdown, importance, inputPhone, phoneCode, phone } = this.state;

    const hasImportance = !!this.props.importance;
    const isUrgent = importance === 'URGENT';
    return (
      <Modal
        isOpen={isOpen}
        toggle={toggle}
        className={`ImportanceModal group-members-modal ${isGroup ? "" : "SINGLE"
          } ${!hasImportance ? "" : "hasImportance"}`}
      >
        <ModalHeader toggle={toggle}>{t('chat.send_sms')}{hasImportance && `: ${isUrgent ? t('Urgent') : t('Important')}!`}</ModalHeader>
        <ModalBody>
          {!hasImportance && (
            <>
              <div className="title">{t('chat.select_urgence')}</div>
              <Dropdown
                className="clo"
                isOpen={dropdown}
                toggle={() => this.setState({ dropdown: !dropdown })}
              >
                <DropdownToggle caret>
                  <span>
                    <span style={{ textTransform: "capitalize" }}>
                      {importance.toLowerCase()}
                    </span>
                  </span>
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem
                    onClick={() => this.setState({ importance: "IMPORTANT" })}
                  >
                    {t('Important')}
                  </DropdownItem>
                  <DropdownItem
                    onClick={() => this.setState({ importance: "URGENT" })}
                  >
                    {t('Urgent')}
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </>
          )}
          {hasImportance && (
            <div className="msg_preview">
              <h2>{t('chat.msg_preview')}</h2>
              <div className="preview_content">
                {textMessage && (
                  <div className={"text" + (isGroup ? ' group' : '')}>
                    {textMessage.length > 256
                      ? textMessage.slice(0, 256).trim() + "..."
                      : textMessage}
                  </div>
                )}
                {files?.length > 0 && (
                  <div className="files">
                    <div className="file_item">
                      <img src={theme === 'dark' ? ImageIconDark : ImageIcon} alt='' />
                      <span title={files[0].name}>{files[0].name}</span>
                    </div>
                    {isGroup && files.length > 1 && <div className="file_item _2">
                      <img src={theme === 'dark' ? ImageIconDark : ImageIcon} alt='' />
                      <span title={files[1].name}>{files[1].name}</span>
                    </div>}
                    {((isGroup && files?.length > 2) || (!isGroup && files.length > 1)) && (
                      <div className="plus_files">+{files.length - (isGroup ? 2 : 1)}</div>
                    )}
                  </div>
                )}
                {audioPreview && <S3AudioPlayer s3key={audioPreview.key} duration={audioPreview?.duration} isAudioPreview/>}
                {copiedVoice && <S3AudioPlayer s3key={copiedVoice.key} duration={copiedVoice.duration} />}
              </div>
            </div>
          )}
          {importance === 'URGENT' && <div className="phone-container">
            <CheckboxComponent
              checked={inputPhone}
              className='CheckBox_'
              label={t('auth.include_phone')}
              onChange={(checked) => {
                this.setState({ inputPhone: checked })
              }}
            />

            {false && inputPhone && <div className="phone_input">
              <SelectField
                options={callingCodes.map(callingCode => {
                  const uniqueName = `(${callingCode.code}) - ${callingCode.country}`;
                  return {
                    value: uniqueName,
                    label: `(${callingCode.code})`,
                    optionLabel: uniqueName
                  }
                })}
                className='phone-code'
                defaultValue={phoneCode}
                hideArrow
                onChange={value => this.setState({ phoneCode: value })}
              />
              <input type="tel"
                id="phone"
                placeholder={t('auth.phone_num')}
                className="phone-number"
                value={phone}
                error={true}
                onChange={(event) => this.setState({ phone: event.target.value })}
              />
            </div>}
          </div>}
          {isGroup && <div className="seperator" />}
          {isGroup && (
            <div className="context col-md-12 align-items-stretch">
              <div className="user-bar col-12">
                <div className="form-group searchSectionContainer">
                  <input
                    type="text"
                    placeholder={t("Search...")}
                    onChange={(event) =>
                      this.setState({
                        selectSearch: _.trim(event.target.value || ""),
                      })
                    }
                  />
                </div>
                <div className="group-scroll_ contactsListSection">
                  {participants.map((contact, i) => {
                    const isSelected = selected.some((x) => x.id === contact.id);
                    if (
                      selectSearch &&
                      !contact.fullName
                        ?.toLowerCase()
                        .includes(selectSearch.toLowerCase())
                    ) {
                      return null;
                    }
                    return (
                      <Item
                        key={i}
                        t={t}
                        contact={contact}
                        allContacts={contacts}
                        selectedList={isSelected}
                        onClick={() => {
                          this.setState({
                            selected: isSelected
                              ? this.state.selected.filter(
                                (x) => x.id !== contact.id
                              )
                              : [...this.state.selected, contact],
                          });
                        }}
                      />
                    );
                  })}
                  {participants?.length > 0 && (
                    <div className="no_result">{t('No_Results')}</div>
                  )}
                </div>
              </div>
            </div>
          )}
        </ModalBody>
        <ModalFooter className="d-flex">
          <Button
            className="cancel_btn"
            color="primary"
            onClick={toggle}
          >
            {t('CANCEL')}
          </Button>
          <Button
            color="primary"
            onClick={this.handleSubmit}
            disabled={participants.length > 1 ? selected.length === 0 : false}
          >
            {t('Send')}
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

const Item = ({
  selectedList,
  contact,
  allContacts,
  isOnlySearchMode = false,
  onClick,
  t
}) => {
  const contactData =
    allContacts.find((item) => item?.id === (contact?.id || contact)) || {};
  const chatStatus = contactData?.connected
    ? contactData.chatStatus
    : "Offline";
  return (
    <div
      key={contactData.id}
      className={`${isOnlySearchMode ? "clickable " : ""
        }d-flex ml-3 align-items-center justify-content-between contactSectionContainer Item`}
      onClick={_.throttle(
        () => isOnlySearchMode && this.handleContactClick(contactData),
        5000,
        { trailing: false }
      )}
    >
      <div className="d-flex contactInfoSectionContainer">
        <div className="d-flex nameAndAvatar">
          <UserAvatar
            user={contactData}
            className={`withStatus mr-2 ${chatStatusClassName(chatStatus)}`}
          />
          <div className="contactInfoCellContainer">
            <p>{contactData.fullName}</p>
            <span>
              <ShowMore
                items={contactData.professions?.map((p) => p.name)}
                placeholder="-"
                header="Professions"
              />
            </span>
          </div>
        </div>
        <div className="contactInfoCellContainer suffix">
          <p>
            <ShowMore
              items={contactData.suffixes?.map((p) => p.name)}
              placeholder="-"
              name={`Suffixes-${contactData.id}`}
            />
          </p>
          <span>{t('Suffix')}</span>
        </div>
        <div className="contactInfoCellContainer group">
          <p>
            <ShowMore
              items={contactData.facilities?.map((p) => p.name)}
              placeholder="-"
              name={`Factilities-${contactData.id}`}
            />
          </p>
          <span>{t('Facility')}</span>
        </div>
        <div className="contactInfoCellContainer department">
          <p>
            <ShowMore
              items={contactData.departments?.map((p) => p.name)}
              placeholder="-"
              name={`Departments-${contactData.id}`}
            />
          </p>
          <span>{t('Department')}</span>
        </div>
        <div className="contactInfoCellContainer position">
          <p>
            <ShowMore
              items={contactData.specialties?.map((p) => p.name)}
              placeholder="-"
              name={`Specialties-${contactData.id}`}
            />
          </p>
          <span>{t('Specialty')}</span>
        </div>
      </div>

      <Button
        className={`${selectedList ? "remove" : "add"}`}
        onClick={(e) => {
          e.target.blur();
          onClick();
        }}
      />
    </div>
  );
};

const mapStateToProps = state => {
  return {
    callingCodes: state.setting.callingCodes,
  };
};

const mapDispatchToProps = dispatch => bindActionCreators({
  getCallingCodesList
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation('common')(ImportanceModal));