/* eslint-disable react/style-prop-object */
import React, { Component } from "react";

import "./EULA.scss";
import { LanguageSelector } from "../../components/UIComponents/LanguageSelector";
import i18n from "../../i18n";
import { AVATAR_BASE, contentsData } from "../../config";
require('dotenv').config();

export default class EULA extends Component {
    state = {
        eulaTextEn: '',
        eulaTextFr: ''
    };

    componentDidMount() {
        this.fetchEulaText();
    }

    fetchEulaText = async () => {
        const tenMinuteWindow = Math.floor(Date.now() / (10 * 60 * 1000)); // only changes every 10 minutes
        const eulaUrl = `${contentsData.CDN_URL}/${contentsData.EULA_PATH}?v=${tenMinuteWindow}`;
        const eulaTexts = await fetch(eulaUrl).then(response => response.json());
        this.setState({ eulaTextEn: eulaTexts.en, eulaTextFr: eulaTexts.fr });
    }

  render() {
    return (
      <div className="EULA">
        <LanguageSelector onChange={i18n.changeLanguage} value={i18n.language} />

        <div className="eula-text" dangerouslySetInnerHTML={{ __html: i18n.language === 'fr' ? this.state.eulaTextFr : this.state.eulaTextEn }}>
        </div>
      </div>
    );
  }
}
